import React, { useState, useEffect } from "react";
import axios from "axios";
import DashNavbar from "./DashNavbar";
import Sidebar from "./Sidebar";
import Pagination from "react-bootstrap/Pagination";
import Modal from "react-bootstrap/Modal";
import { FcCancel, FcApproval } from "react-icons/fc";
import { IoWarningOutline } from "react-icons/io5";

function DocumentViewList() {
  const [documents, setDocuments] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const itemsPerPage = 5;

  useEffect(() => {
    fetchDocuments();
  }, [currentPage, searchTerm]);

  const fetchDocuments = async () => {
    try {
      const response = await axios.get(
        `https://raasbackend.vetrinahealthcare.com/api/documents/?page=${currentPage}`
      );
      setDocuments(response.data);
      filterDocuments(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const filterDocuments = (data) => {
    const filtered = data.filter(
      (document) =>
        document.username.toLowerCase().includes(searchTerm.toLowerCase()) ||
        document.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredDocuments(filtered);
  };

  const handlePageChange = (page) => {
    if (page < 1 || page > totalPages) {
      return;
    }
    setCurrentPage(page);
  };

  const totalPages = Math.ceil(filteredDocuments.length / itemsPerPage);
  const paginatedDocuments = filteredDocuments.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const handleViewDetails = (document) => {
    setSelectedDocument(document);
  };

  const handleCloseDetails = () => {
    setSelectedDocument(null);
  };

  const handleOpenDocument = async (documentType) => {
    try {
      const response = await axios.post(
        "https://raasbackend.vetrinahealthcare.com/api/view_document/",
        { email: selectedDocument.email, document_type: documentType },
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error opening document:", error);
    }
  };
  const handleAcceptReject = async (documentType, status) => {
    try {
      await axios.post(
        "https://raasbackend.vetrinahealthcare.com/api/update_document_status/",
        {
          email: selectedDocument.email,
          document_type: documentType,
          status: status,
        }
      );

      // Update the status in the selectedDocument state
      setSelectedDocument((prev) => ({
        ...prev,
        [`${documentType}_status`]: status,
      }));

      // Refresh the document list
      fetchDocuments();
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const iconStyle = { fontSize: "200%" };
  return (
    <>
      <DashNavbar />
      <div className="wrapper">
        <Sidebar />
        <div className="main">
          <div className="container">
            <div className="auth-inner mt-2 w-100">
              <h2 className="mb-4 gradient-text">Document View List</h2>
              <div className="input-group justify-content-center align-items-center">
                <div className="form-outline w-50">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search by Name or Email"
                    value={searchTerm}
                    onChange={handleSearch}
                  />
                  <button className="btn btn-primary searchicon text-xs">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginatedDocuments.map((document, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{document.username}</td>
                        <td>{document.email}</td>
                        <td>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={() => handleViewDetails(document)}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="d-flex justify-content-start mt-4">
                <Pagination>
                  <Pagination.Prev
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  />
                  {Array.from({ length: totalPages }, (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === currentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      {selectedDocument && (
        <Modal show={true} onHide={handleCloseDetails} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              {selectedDocument.username} ({selectedDocument.email})
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">Document Name</th>
                  <th scope="col">View PDF</th>
                  <th scope="col">Action</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Aadhar</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleOpenDocument("aadhar")}
                      disabled={!selectedDocument.aadhar}
                    >
                      View PDF
                    </button>
                  </td>
                  <td>
                    <button
                      className="me-2 btn btn-success btn-sm"
                      onClick={() => handleAcceptReject("aadhar", "Y")}
                      disabled={!selectedDocument.aadhar}
                    >
                      Accept
                    </button>
                    <button
                      className="me-2 btn btn-danger btn-sm"
                      onClick={() => handleAcceptReject("aadhar", "N")}
                      disabled={!selectedDocument.aadhar}
                    >
                      Reject
                    </button>
                  </td>
                  <td>
                    {selectedDocument.aadhar_status === "Y" && (
                      <FcApproval style={iconStyle} className="me-2" />
                    )}
                    {selectedDocument.aadhar_status === "N" && (
                      <FcCancel style={iconStyle} />
                    )}
                    {selectedDocument.aadhar_status === null && (
                      <IoWarningOutline style={iconStyle} />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>PAN Card</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleOpenDocument("pan")}
                      disabled={!selectedDocument.pan}
                    >
                      View PDF
                    </button>
                  </td>
                  <td>
                    <button
                      className="me-2 btn btn-success btn-sm"
                      onClick={() => handleAcceptReject("pan", "Y")}
                      disabled={!selectedDocument.pan}
                    >
                      Accept
                    </button>
                    <button
                      className="me-2 btn btn-danger btn-sm"
                      onClick={() => handleAcceptReject("pan", "N")}
                      disabled={!selectedDocument.pan}
                    >
                      Reject
                    </button>
                  </td>
                  <td>
                    {selectedDocument.pan_status === "Y" && (
                      <FcApproval style={iconStyle} className="me-2" />
                    )}
                    {selectedDocument.pan_status === "N" && (
                      <FcCancel style={iconStyle} />
                    )}
                    {selectedDocument.pan_status === null && (
                      <IoWarningOutline style={iconStyle} />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>HSC</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleOpenDocument("hsc")}
                      disabled={!selectedDocument.hsc}
                    >
                      View PDF
                    </button>
                  </td>
                  <td>
                    <button
                      className="me-2 btn btn-success btn-sm"
                      onClick={() => handleAcceptReject("hsc", "Y")}
                      disabled={!selectedDocument.hsc}
                    >
                      Accept
                    </button>
                    <button
                      className="me-2 btn btn-danger btn-sm"
                      onClick={() => handleAcceptReject("hsc", "N")}
                      disabled={!selectedDocument.hsc}
                    >
                      Reject
                    </button>
                  </td>
                  <td>
                    {selectedDocument.hsc_status === "Y" && (
                      <FcApproval style={iconStyle} className="me-2" />
                    )}
                    {selectedDocument.hsc_status === "N" && (
                      <FcCancel style={iconStyle} />
                    )}
                    {selectedDocument.hsc_status === null && (
                      <IoWarningOutline style={iconStyle} />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>SSC</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleOpenDocument("ssc")}
                      disabled={!selectedDocument.ssc}
                    >
                      View PDF
                    </button>
                  </td>
                  <td>
                    <button
                      className="me-2 btn btn-success btn-sm"
                      onClick={() => handleAcceptReject("ssc", "Y")}
                      disabled={!selectedDocument.ssc}
                    >
                      Accept
                    </button>
                    <button
                      className="me-2 btn btn-danger btn-sm"
                      onClick={() => handleAcceptReject("ssc", "N")}
                      disabled={!selectedDocument.ssc}
                    >
                      Reject
                    </button>
                  </td>
                  <td>
                    {selectedDocument.ssc_status === "Y" && (
                      <FcApproval style={iconStyle} className="me-2" />
                    )}
                    {selectedDocument.ssc_status === "N" && (
                      <FcCancel style={iconStyle} />
                    )}
                    {selectedDocument.ssc_status === null && (
                      <IoWarningOutline style={iconStyle} />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>Degree</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleOpenDocument("degree")}
                      disabled={!selectedDocument.degree}
                    >
                      View PDF
                    </button>
                  </td>
                  <td>
                    <button
                      className="me-2 btn btn-success btn-sm"
                      onClick={() => handleAcceptReject("degree", "Y")}
                      disabled={!selectedDocument.degree}
                    >
                      Accept
                    </button>
                    <button
                      className="me-2 btn btn-danger btn-sm"
                      onClick={() => handleAcceptReject("degree", "N")}
                      disabled={!selectedDocument.degree}
                    >
                      Reject
                    </button>
                  </td>
                  <td>
                    {selectedDocument.degree_status === "Y" && (
                      <FcApproval style={iconStyle} className="me-2" />
                    )}
                    {selectedDocument.degree_status === "N" && (
                      <FcCancel style={iconStyle} />
                    )}
                    {selectedDocument.degree_status === null && (
                      <IoWarningOutline style={iconStyle} />
                    )}
                  </td>
                </tr>

                <tr>
                  <td>Other</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleOpenDocument("other")}
                      disabled={!selectedDocument.other}
                    >
                      View PDF
                    </button>
                  </td>
                  <td>
                    <button
                      className="me-2 btn btn-success btn-sm"
                      onClick={() => handleAcceptReject("other", "Y")}
                      disabled={!selectedDocument.other}
                    >
                      Accept
                    </button>
                    <button
                      className="me-2 btn btn-danger btn-sm"
                      onClick={() => handleAcceptReject("other", "N")}
                      disabled={!selectedDocument.other}
                    >
                      Reject
                    </button>
                  </td>
                  <td>
                    {selectedDocument.other_status === "Y" && (
                      <FcApproval style={iconStyle} className="me-2" />
                    )}
                    {selectedDocument.other_status === "N" && (
                      <FcCancel style={iconStyle} />
                    )}
                    {selectedDocument.other_status === null && (
                      <IoWarningOutline style={iconStyle} />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}

export default DocumentViewList;
