import React, { useState, useEffect } from "react";
import DashNavbar from "./DashNavbar";
import Sidebar from "./Sidebar";
import ApplicantDetailsModal from "../ProfileForm/ApplicantDetailsModal";
import "../css/dash.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/PDFViewer.css";
import { Link } from "react-router-dom";
import { Pagination } from "react-bootstrap";

function InterviewerDashboard() {
  const [interviewSchedule, setInterviewSchedule] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [appliedJobs, setAppliedJobs] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchInterviewSchedule();
  }, []);

  const fetchInterviewSchedule = () => {
    const userEmail = localStorage.getItem("email");
    if (!userEmail) {
      console.error("User email not found in local storage");
      return;
    }

    fetch(`https://raasbackend.vetrinahealthcare.com/interviewers/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: userEmail }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        const formattedData = data.map((interview) => ({
          ...interview,
          date: formatDate(interview.date),
          time: formatTime(interview.time),
        }));
        setInterviewSchedule(formattedData);

        // Fetch applied jobs for the interview schedule
        fetchAppliedJobsForSchedule(formattedData);
      })
      .catch((error) => {
        console.error("Error fetching interview schedule:", error);
      });
  };

  const fetchAppliedJobsForSchedule = (schedule) => {
    const candidateEmails = schedule.map(
      (interview) => interview.candidate_email
    );
    candidateEmails.forEach((email) => {
      fetch(
        `https://raasbackend.vetrinahealthcare.com/get_applied_job/${email}/`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.json();
        })
        .then((appliedJobsResponse) => {
          setAppliedJobs((prevJobs) => ({
            ...prevJobs,
            [email]: appliedJobsResponse.job_titles || ["Not Applied"],
          }));
        })
        .catch((error) => {
          console.error("Error fetching applied jobs:", error);
        });
    });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    return formattedDate;
  };

  const formatTime = (timeString) => {
    const time = new Date(`01/01/2022 ${timeString}`);
    const formattedTime = time.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
    });
    return formattedTime;
  };

  const handleViewClick = (candidateEmail) => {
    fetch("https://raasbackend.vetrinahealthcare.com/get_applicant_data/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: candidateEmail }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      })
      .then((data) => {
        setSelectedApplicant(data);
        setShowModal(true);
      })
      .catch((error) => {
        console.error("Error fetching applicant data:", error);
      });
  };

  const handleViewResume = (candidateEmail) => {
    fetch("https://raasbackend.vetrinahealthcare.com/view_resume/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: candidateEmail }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const resumeBlobUrl = URL.createObjectURL(blob);
        window.open(resumeBlobUrl, "_blank");
      })
      .catch((error) => {
        console.error("Error fetching resume:", error);
      });
  };

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = interviewSchedule.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(interviewSchedule.length / itemsPerPage);
  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredSchedule = interviewSchedule.filter((interview) => {
    const candidateMatch = interview.candidate
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const dateMatch = interview.date
      .toLowerCase()
      .includes(searchQuery.toLowerCase());
    const appliedJobMatch = appliedJobs[interview.candidate_email]?.some(
      (job) => job.toLowerCase().includes(searchQuery.toLowerCase())
    );
    return candidateMatch || dateMatch || appliedJobMatch;
  });

  return (
    <>
      {/* <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      > */}
      <DashNavbar />
      <div className="wrapper">
        <Sidebar />
        <div className="col-lg-10 col-md-8 col-sm-12 main dash4">
          <div className="container mt-4">
            <div className="text-start">
              <h3>Welcome to your Dashboard</h3>
              <p>Below is your interview schedule:</p>
            </div>
            <div className="auth-inner mt-5 w-100">
              <h2 className="mb-4">Interview Schedule</h2>
              <div className="input-group justify-content-center align-items-center">
                <div className="form-outline w-50">
                  <input
                    type="search"
                    className="form-control"
                    placeholder="Search by Name or Email"
                    onChange={handleSearch}
                  />
                  <button className="btn btn-primary searchicon text-xs">
                    <i className="fas fa-search"></i>
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead className="thead-dark">
                    <tr>
                      <th scope="col">Sr.No</th>
                      <th scope="col">Candidate</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Applied Job</th>
                      <th scope="col">Meeting Link</th>
                      <th scope="col">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredSchedule
                      .slice(indexOfFirstItem, indexOfLastItem)
                      .map((interview, index) => (
                        <tr key={index}>
                          <td>{indexOfFirstItem + index + 1}</td>
                          <td>{interview.candidate}</td>
                          <td>{interview.date}</td>
                          <td>{interview.time}</td>
                          <td className="w-25">
                            {appliedJobs[interview.candidate_email]?.join(", ")}
                          </td>
                          <td>
                            <Link
                              to={interview.meetLink}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="btn btn-primary"
                            >
                              Join Meeting
                            </Link>
                          </td>
                          <td>
                            <button
                              className="btn btn-secondary me-2"
                              onClick={() =>
                                handleViewClick(interview.candidate_email)
                              }
                            >
                              View
                            </button>
                            <button
                              className="btn btn-secondary"
                              onClick={() =>
                                handleViewResume(interview.candidate_email)
                              }
                            >
                              Resume
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <Pagination className="mt-4">
                {/* <Pagination.First onClick={() => paginate(1)} disabled={currentPage === 1} /> */}
                <Pagination.Prev
                  onClick={() => paginate(currentPage - 1)}
                  disabled={currentPage === 1}
                />
                {Array.from({ length: totalPages }, (_, i) => (
                  <Pagination.Item
                    key={i + 1}
                    active={i + 1 === currentPage}
                    onClick={() => paginate(i + 1)}
                  >
                    {i + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => paginate(currentPage + 1)}
                  disabled={currentPage === totalPages}
                />
                {/* <Pagination.Last onClick={() => paginate(totalPages)} disabled={currentPage === totalPages} /> */}
              </Pagination>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}

      <ApplicantDetailsModal
        applicant={selectedApplicant || {}}
        show={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  );
}

export default InterviewerDashboard;
