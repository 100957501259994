import React, { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AuthService from "./authService";
import Navbar from "../Misc/Navbar";
import { Link } from "react-router-dom";
import log_in from "../../Assets/login.png";

export default function Login() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    role: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleLogin = (event) => {
    event.preventDefault();

    const loginData = {
      email: formData.email,
      password: formData.password,
    };

    AuthService.login(loginData.email, loginData.password);

    axios
      .post("https://raasbackend.vetrinahealthcare.com/Login_view/", loginData)
      .then((response) => {
        console.log("Login Successful:", response.data);

        const { user, message, error_message } = response.data;

        if (error_message) {
          Swal.fire({
            icon: "error",
            title: "Login Error",
            text: error_message,
            timer: 4000,
            timerProgressBar: true,
            showCancelButton: false,
          });
        } else if (user) {
          const { role } = user;
          const userName = user.name;
          const userId = user.id;
          localStorage.setItem("User_Role", role);
          localStorage.setItem("UserName", userName);
          localStorage.setItem("user_id", userId);
          localStorage.setItem("email", formData.email);
          Swal.fire({
            icon: "success",
            title: "Login Successful!",
            text: message || "Welcome!",
            timer: 1000,
            timerProgressBar: true,
            showConfirmButton: false,
          }).then(() => {
            if (role === "Admin") {
              navigate("/Admindashboard");
            } else if (role === "Candidate") {
              navigate("/dashboard");
            } else if (role === "Interviewer") {
              navigate("/InterviewerDashboard");
            } else if (role === "HR") {
              navigate("/HRDashboard");
            }
          });
        }
      })
      .catch((error) => {
        console.error("Login Error:", error.response);

        if (error.response.status === 403) {
          Swal.fire({
            title: "Login Error",
            html: '<span style="color: red;">Your account is inactive. Please contact the administrator.</span>',
            icon: "warning",
            backdrop: true,
            showConfirmButton: false,
            timer: 5000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            showClass: {
              popup: "animate__animated animate__fadeIn",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOut",
            },
            customClass: {
              popup: "custom-modal-class",
              title: "custom-title-class",
            },
            buttonsStyling: false,
            confirmButtonClass: "btn btn-warning",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Login Error",
            text: "Invalid email or password. Please try again.",
            timer: 4000,
            timerProgressBar: true,
            showConfirmButton: false,
          });
        }
      });
  };

  return (
    <>
      <div
        className="vh-100 "
        style={{
          backgroundImage: `url(${log_in})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          overflow: "hidden",
        }}
      >
        <Navbar />
        <div className="d-flex justify-content-end pe-5 me-4">
          <div
            className="signup-box mt-5 me-5 "
            style={{ padding: "20px", borderRadius: "8px" }}
          >
            <form onSubmit={handleLogin}>
              <h3 style={{ marginBottom: "30px", fontSize: "40px" }}>
                Sign In
              </h3>
              <div className="input-container">
                <div className="icon-container">
                  <i className="fa-solid fa-user"></i>
                </div>
                <input
                  type="email"
                  autoComplete="off"
                  className="email-input"
                  required
                  placeholder="Enter Email"
                  name="email"
                  onChange={handleInputChange}
                  value={formData.email}
                />
              </div>
              &nbsp;
              <div className="input-container">
                <div className="icon-container">
                  <i className="fa-solid fa-lock"></i>
                </div>
                <input
                  type="Password"
                  className="email-input"
                  required
                  name="password"
                  placeholder="Enter Password"
                  value={formData.password}
                  onChange={handleInputChange}
                />
              </div>
              <div className="mb-3 d-flex flex-column align-items-start">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                  />

                  <label
                    className="custom-control-label"
                    style={{ marginTop: "10px" }}
                    htmlFor="customCheck1"
                  >
                    {" "}
                    &nbsp; Remember me
                  </label>
                </div>
              </div>
              <div className="d-grid " style={{ justifyItems: "center" }}>
                <button type="submit" className="btn-shiny2 ">
                  LOGIN
                </button>
              </div>
              <p className="forgot-password text-center">
                {/* Forgot{" "} */}
                <Link to="/forgotPass" style={{ color: "grey" }}>
                  {" "}
                  Forgot password?
                </Link>
              </p>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
